<template>
  <div v-if="!loading">
    <v-container fluid fill-height class="mt-5" v-if="confirmed">
      <CategoryTitle
        :category="category"
        :centered="true"
        class="default--text"
      />
      <v-row no-gutters justify="center" justify-sm="start">
        <Login
          class="mx-auto"
          :hideLogo="true"
          :hideRegistration="true"
          @submit="goToHome"
        ></Login>
      </v-row>
    </v-container>
    <v-container v-else fluid fill-height class="mt-5 d-flex flex-column">
      <h1 style="line-height: 3rem">
        {{ $t("register.registrationConfirmed.error") }}
      </h1>
      <div v-html="error" class="mt-4" v-if="error"></div>
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.login {
  max-width: 600px;
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 60%;
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle";
import categoryMixins from "~/mixins/category";
import loginMixins from "~/mixins/login";
import PoliCustomService from "@/service/poliCustomService";
import Login from "@/components/Login";

export default {
  name: "RegisterConfirmed",
  components: { CategoryTitle, Login },
  props: { showLogin: { type: Boolean, default: false } },
  data() {
    return {
      token: null,
      confirmed: null,
      error: null,
      loading: false
    };
  },
  mixins: [categoryMixins, loginMixins],
  methods: {
    async confirm() {
      try {
        this.loading = true;
        this.error = null;
        let response = await PoliCustomService.confirmDoubleOptin(this.token);
        if (response && response.status === 0) {
          this.confirmed = true;
        } else {
          if (response && response.errors.length) {
            this.error = response.errors[0].error;
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    },
    async goToHome() {
      if (await this.needAddress()) {
        if (await this.needTimeslot()) {
          this.$router.push({
            name: "Home",
            path: "/"
          });
        }
      }
    }
  },
  created() {
    if (this.$route.query.code) {
      this.token = this.$route.query.code;
    }
    this.confirm();
  }
};
</script>

<style></style>
